import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/waimai',
    component: () => import('../views/waimai.vue')
  },
  {
    path: '/wmdetail',
    component: () => import('../views/waimai2.vue')
  },
  {
    path: '/daodian',
    component: () => import('../views/daodian.vue')
  },
  {
    path: '/dddetail',
    component: () => import('../views/daodian2.vue')
  },
  {
    path:"/img/setimg888",
    component: ()=>import("../views/setimg.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
